<template>
    <section>
        <v-row class="fill-height">
            <v-col>
                <v-btn :to="`/strategytemplates/create`" color="primary">
                    <v-icon left>fa-plus</v-icon>New Strategy Template
                </v-btn>
            </v-col>
        </v-row>

        <v-row v-if="noTemplates">
            <v-card class="card-center" flat tile style="background-color: #ecf2f6 !important; font-weight: 800">
                No strategy templates configured
            </v-card>
        </v-row>
        <section>
            <v-row justify="start" align="start">
            <v-col v-for="template in strategyTemplates" :key="template.name" :sm="12" :md="6" :lg="4" :xl="3">
                <v-card>
                    <v-card-title class="title blue-grey lighten-4">
                        {{ template.name }}
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        <p>{{ template.description }}</p>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>                     
                        <v-btn icon :to="`/strategytemplates/${template.id}`">
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn icon @click="handleDeleteStrategyTemplate(template)">
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </v-card-actions>
                </v-card>
                </v-col>
            </v-row>
            <confirm-dialog ref="confirmDelete" title="Delete this strategy template?"></confirm-dialog>
        </section>
    </section>
</template>

<script>
import api from "./_api";
import ConfirmDialog from "../../../src/components/Dialogs/ConfirmDialog";

export default {
    components: {
        "confirm-dialog": ConfirmDialog,
    },

    data() {
        return {
            strategyTemplates: []
        };
    },

    async created() {
      this.strategyTemplates = await api.getStrategyTemplates();
    },

    methods: {
        async handleDeleteStrategyTemplate(template) {
            const msg = "Are you sure you want to delete this strategy template?";
            const confirmed = await this.$refs.confirmDelete.confirm(msg);
            if (!confirmed) {
                return;
            }
            console.log(template)
            await api.deleteStrategyTemplate(template);
            this.strategyTemplates = await api.getStrategyTemplates();
        },
    },

    computed: {
        noTemplates: function () {
            return this.strategyTemplates.length === 0;
        },
    },
  }
</script>
